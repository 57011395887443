import { Button, Modal } from 'react-bootstrap';

export const ModalPurchaseWarning = ({ show, onHide, onConfirm }) => {
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                Пожалуйста, подтвердите метод оплаты
            </Modal.Header>
            <Modal.Body>
                В связи с меняющимся курсом валют, просим связаться с менеджером перед оплатой онлайн позиции под заказ
            </Modal.Body>
            <Modal.Footer bsPrefix="modal-footer">
                <Button
                    bsPrefix="btn button_wrapper"
                    onClick={onHide}>
                    <u>Отмена</u>
                </Button>
                <Button
                    bsPrefix="btn button_main_color-rounded"
                    onClick={onConfirm}>
                    Подтвердить заказ
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
