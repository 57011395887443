import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faVk, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';

const SocialIcons = ({ size }) => {
    return (
        <>
            <a
                className="header-social_links-icon whatsapp"
                href="https://wa.me/79515477008"
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={faWhatsappSquare} size={size} />
            </a>
            {/*<a*/}
            {/*    className="header-social_links-icon vkontakte"*/}
            {/*    href="https://vk.com/ultranout"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer">*/}
            {/*    <FontAwesomeIcon icon={faVk} size={size} />*/}
            {/*</a>*/}
            <a
                className="header-social_links-icon"
                href="https://market.yandex.ru/store--ultra-nout?businessId=13529706"
                target="_blank"
                rel="noreferrer">
                <span className="yandex_market"></span>
            </a>
            {/*<a*/}
            {/*    className="header-social_links-icon"*/}
            {/*    href="https://megamarket.ru/shop/ultra-nout/catalog/elektronika/"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer">*/}
            {/*    <span className="mega_market"></span>*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    className="header-social_links-icon telegram"*/}
            {/*    href="https://t.me/UltraNout"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer">*/}
            {/*    <FontAwesomeIcon icon={faTelegram} size={size} />*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    style={{ fontSize: '16px' }}*/}
            {/*    className="header-social_links-text avito"*/}
            {/*    href="https://www.avito.ru/user/2308f68101d1fdf35b4385381ef87571/profile?src=search_seller_info"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer">*/}
            {/*    Авито*/}
            {/*</a>*/}
        </>
    );
};

export default SocialIcons;
