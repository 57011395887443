import { useCallback, useState } from 'react';

/**
 * Хук для получения обработчиков toggle
 */
//TODO типизировать при переходе на тайпскрипт
//TODO export const useToggle = (initialValue: boolean = false): [boolean, () => void]
export const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);

    const toggle = useCallback(() => setValue((prev) => !prev), []);

    return [value, toggle];
};
